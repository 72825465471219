<template>
<div class="mt-2">
    <v-container >
      <v-row>
        <h2 style="color:#1F306E;text-align:center" class="ml-3"><span style="color:#f5487f;">Crea</span> tu cuenta</h2>
      </v-row>
      </v-container>
         <v-container class="text-center">

<v-row aling="center"  justify="center"><v-col cols="12" sm="10" md="6" lg="4">
     <v-form>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-text-field type="number" v-model="boxii" label="ID" placeholder="# Alcancia" filled rounded></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-text-field v-model="nombre" label="Nombre" placeholder="Pepito" filled rounded></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-text-field v-model="email" label="Correo electrónico" placeholder="pepito@ejemplo.com" filled rounded></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-text-field v-model="password1" label="Contraseña" placeholder="••••••••••••••" type="password" filled rounded></v-text-field>
        </v-col>
        <v-col cols="12" sm="12" md="12">
          <v-text-field v-model="password2" label="Repetir contraseña" placeholder="••••••••••••••" type="password" filled rounded></v-text-field>
        </v-col>        
        <v-btn @click="crear()" rounded block color="#1F306E" dark height="7vh">Crear</v-btn>
      </v-row>
    </v-container>
  <v-dialog v-model="dialog" max-width="290"><v-card><br><v-card-text>{{this.alerttext}}</v-card-text><v-card-actions><v-spacer></v-spacer><v-btn color="green darken-1" text @click="volver()">Ok</v-btn></v-card-actions></v-card></v-dialog>
  </v-form>

<div class="text-center">
  </div><br>
    <div class="text-center">
      <a @click="volver()" style="text-decoration:none !important; color:#f5487f">Volver</a>  
    </div>                
    </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
import axios from 'axios'
  export default {
    name: 'crearcuenta',
    components: {
    },
    mounted(){
    },
    data: function(){
      return{boxii:"",nombre:"",email:"",password1:"",password2:"",error:false,errorMessages: '',dialog:false,alerttext:'',}
    },
    methods:{
      volver()
      {
        this.$router.push('/');
      },
      crear(){
        this.err=false
        if (this.boxii.length!=0 &&this.nombre.length!=0 && this.email.length!=0 && this.password1.length!=0 && this.password2.length!=0 )
        {
          if (this.password1==this.password2)
          {
            let json={"funcion":1,"identificacion":this.boxii,"nombre":this.nombre,"usuario":this.email,"password":this.password1}
            axios.post("https://app.boxii.com.co/api/servicio.php",json)
            .then(data=>{
                console.log(data)
                alert("Cuenta Creada Exitosamente")
                this.$router.push('/');
              })
            .catch(error => {
              alert(error)
            console.log(error);
            }
            )
          }
          else
          {
          this.alerttext="las contraseñas no coinciden";
          this.dialog=true;            
          }
        }
        else
        {
          this.alerttext="campo vacio";
          this.dialog=true;
        }
        },
    },
  }
</script>
<template>
<div>
         <v-container class="text-center">
          <v-row aling="center" class="mt-2" justify="center"><v-col cols="12" sm="10" md="6" lg="4">
            <v-img alt="Vuetify Logo" class="mx-auto" contain src="../assets/index.png" width="220" /><br>
              <svg width="152" height="53" viewBox="0 0 152 53" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M21.2088 52.6615C18.3612 52.6615 15.8239 52.0205 13.5937 50.7385C11.3635 49.4566 9.60893 47.702 8.32695 45.4718V49.6781C8.32695 50.9305 7.31081 51.9467 6.05838 51.9467H2.26857C1.01613 51.9467 0 50.9305 0 49.6781V2.26857C0 1.01613 1.01613 0 2.26857 0H7.26651C8.51894 0 9.53508 1.01613 9.53508 2.26857V21.6341C10.8614 19.4039 12.6071 17.6493 14.7664 16.3674C16.9256 15.0854 19.4512 14.4474 22.346 14.4474C24.7652 14.4474 26.9717 14.9525 28.9626 15.9597C30.9536 16.97 32.6727 18.3642 34.1231 20.1483C35.5704 21.9325 36.6841 23.9736 37.4668 26.2717C38.2496 28.5698 38.6425 31.0333 38.6425 33.6593C38.6425 36.2882 38.2023 38.7488 37.325 41.0499C36.4477 43.348 35.2248 45.3773 33.6593 47.1378C32.0937 48.8983 30.2535 50.2571 28.1444 51.22C26.0354 52.18 23.7225 52.6615 21.2088 52.6615ZM18.5769 44.5502C20.0951 44.5502 21.4835 44.2607 22.7389 43.6759C23.9942 43.0939 25.0872 42.3141 26.0117 41.3393C26.9363 40.3616 27.6482 39.2096 28.1474 37.8863C28.6466 36.56 28.8947 35.1746 28.8947 33.7331C28.8947 31.7304 28.4782 29.8813 27.6482 28.1828C26.8181 26.4844 25.6779 25.1226 24.2335 24.0976C22.7861 23.0756 21.0906 22.5616 19.1469 22.5616C17.7704 22.5616 16.453 22.8747 15.1976 23.5039C13.9393 24.1331 12.8257 24.9808 11.8539 26.0501C10.882 27.1194 10.1111 28.3305 9.54099 29.6804V38.4062C9.82456 39.3366 10.2883 40.1755 10.9293 40.9199C11.5703 41.6643 12.3176 42.3053 13.1713 42.8399C14.025 43.3746 14.9141 43.794 15.8386 44.0953C16.7602 44.3995 17.673 44.5502 18.5769 44.5502Z" fill="#1F306E"/>
              <path d="M62.3383 52.6615C59.3018 52.6615 56.5754 52.1564 54.1561 51.1491C51.7369 50.1419 49.684 48.7447 48.0003 46.9605C46.3166 45.1794 45.0228 43.1382 44.1219 40.8372C43.2209 38.5391 42.769 36.1228 42.769 33.5884C42.769 31.0097 43.218 28.5698 44.1219 26.2687C45.0228 23.9706 46.3166 21.9295 48.0003 20.1454C49.684 18.3642 51.7369 16.967 54.1561 15.9568C56.5754 14.9495 59.3018 14.4444 62.3383 14.4444C65.3749 14.4444 68.0895 14.9495 70.4851 15.9568C72.8807 16.967 74.9336 18.3612 76.641 20.1454C78.3483 21.9295 79.6421 23.9706 80.5194 26.2687C81.3967 28.5668 81.8368 31.0067 81.8368 33.5884C81.8368 36.1199 81.3967 38.5361 80.5194 40.8372C79.6421 43.1353 78.3601 45.1764 76.6764 46.9605C74.9927 48.7447 72.9398 50.1389 70.5205 51.1491C68.1043 52.1564 65.3749 52.6615 62.3383 52.6615ZM52.5906 33.5884C52.5906 35.73 53.0189 37.6263 53.8726 39.2775C54.7262 40.9288 55.8871 42.2225 57.3581 43.153C58.8292 44.0835 60.4892 44.5472 62.3383 44.5472C64.1402 44.5472 65.7766 44.0717 67.2477 43.1176C68.7187 42.1635 69.8914 40.8608 70.7687 39.2096C71.646 37.5584 72.0861 35.662 72.0861 33.5205C72.0861 31.4262 71.646 29.5416 70.7687 27.8668C69.8914 26.1919 68.7157 24.8893 67.2477 23.9588C65.7766 23.0283 64.1402 22.5616 62.3383 22.5616C60.4892 22.5616 58.8262 23.0372 57.3581 23.9913C55.8871 24.9454 54.7262 26.248 53.8726 27.9022C53.0159 29.5534 52.5906 31.4498 52.5906 33.5884Z" fill="#1F306E"/>
              <path d="M93.3274 16.0129L100.479 26.5759L101.049 27.8461L101.761 26.5759L108.844 16.0217C109.266 15.3926 109.972 15.0174 110.729 15.0174H115.18C117.017 15.0174 118.093 17.0881 117.035 18.5916L106.599 33.4141L117.218 48.3666C118.285 49.8701 117.212 51.9496 115.369 51.9496H110.912C110.17 51.9496 109.479 51.5892 109.054 50.9807L101.69 40.462L101.049 39.2628L100.479 40.462L93.1856 50.9748C92.7632 51.5863 92.0661 51.9496 91.3217 51.9496H86.8968C85.0477 51.9496 83.9754 49.8583 85.0536 48.3577L95.6373 33.6238L85.1658 18.5798C84.1202 17.0763 85.1954 15.0145 87.0268 15.0145H91.4428C92.2019 15.0174 92.905 15.3896 93.3274 16.0129Z" fill="#1F306E"/>
              <path d="M124.319 49.681V16.9315C124.319 15.6791 125.335 14.663 126.588 14.663H131.586C132.838 14.663 133.854 15.6791 133.854 16.9315V49.681C133.854 50.9335 132.838 51.9496 131.586 51.9496H126.588C125.335 51.9496 124.319 50.9335 124.319 49.681Z" fill="#1F306E"/>
              <path d="M142.465 7.86316V1.60688C142.465 0.720717 143.183 0.00292969 144.069 0.00292969H150.396C151.282 0.00292969 152 0.720717 152 1.60688V5.11903C152 7.52052 150.053 9.46712 147.652 9.46712H144.069C143.183 9.46712 142.465 8.74932 142.465 7.86316Z" fill="#F5487F"/>
              <path d="M142.465 49.681V16.9315C142.465 15.6791 143.481 14.663 144.733 14.663H149.731C150.984 14.663 152 15.6791 152 16.9315V49.681C152 50.9335 150.984 51.9496 149.731 51.9496H144.733C143.481 51.9496 142.465 50.9335 142.465 49.681Z" fill="#F5487F"/>
              <path d="M139.629 7.86316V1.60688C139.629 1.60097 139.629 1.59506 139.629 1.58915C139.632 0.723671 138.982 0.00292969 138.117 0.00292969H129.214C126.694 0.00292969 124.494 1.90522 124.331 4.41896C124.154 7.17492 126.337 9.46712 129.054 9.46712H138.049C138.923 9.46712 139.635 8.75227 139.632 7.88088L139.629 7.86316Z" fill="#1F306E"/>
              </svg><br><br><br>
                <div class="text-center">
                  <v-btn @click="login()" rounded block color="#1F306E" dark height="10vh">Iniciar sesión</v-btn>
                </div><br>
                <div class="text-center">
                  <a @click="crear()" style="text-decoration:none !important; color:#f5487f">Crea tu cuenta</a>  
                </div>                
            </v-col>
            </v-row>
         </v-container>
         </div>
</template>

<script>
import axios from 'axios'
  export default {
    name: 'Home',
    components: {
    },
    mounted(){
    },
    methods:{
      crear()
      {
        this.$router.push('/Crear');
      },
      login(){
        this.$router.push('/Login');
      },
    },
  }
</script>